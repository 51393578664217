import { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import Modal from './components/Modal'
import NumericInput from 'react-numeric-input';
import { Parallax } from 'react-parallax';
import { Medium } from 'react-bootstrap-icons';
import { Twitter } from 'react-bootstrap-icons';
import { Discord } from 'react-bootstrap-icons';
import AnchorLink from 'react-anchor-link-smooth-scroll'


import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Container, Row, Col, Navbar, Nav, Image, Figure, Accordion, Form } from 'react-bootstrap';

import layer1 from './assets/1.png';
import layer2 from './assets/2.png';
import layer3 from './assets/3.png';
import layer4 from './assets/4.png';
import layer5 from './assets/5.png';
import layer6 from './assets/6.png';
import layer7 from './assets/7.png';
import layer8 from './assets/logo.png';
import introductionImage from './assets/introduction.png';
import triptalksImage from './assets/Image_13.png';
import mentoringEye from './assets/backgrounds/eye_buddha.png';
import creator1Image from './assets/Image_13.png';
import creator2Image from './assets/Image_11.png';
import creator3Image from './assets/323.png';
import creator4Image from './assets/630.png';
import creator5Image from './assets/320.png';
import logo from './assets/logo_cropped.png';


import blue1 from './assets/shrooms/mushroom_blue_1.png';
import blue2 from './assets/shrooms/mushroom_blue_2.png';
import blue3 from './assets/shrooms/mushroom_blue_3.png';
import blue4 from './assets/shrooms/mushroom_blue_4.png';
import blue5 from './assets/shrooms/mushroom_blue_5.png';

import flying1 from './assets/shrooms/mushroom_flying_1.png';
import flying2 from './assets/shrooms/mushroom_flying_2.png';
import flying3 from './assets/shrooms/mushroom_flying_3.png';
import flying4 from './assets/shrooms/mushroom_flying_4.png';
import flying5 from './assets/shrooms/mushroom_flying_5.png';

import golden1 from './assets/shrooms/mushroom_golden_1.png';
import golden2 from './assets/shrooms/mushroom_golden_2.png';
import golden3 from './assets/shrooms/mushroom_golden_3.png';
import golden4 from './assets/shrooms/mushroom_golden_4.png';
import golden5 from './assets/shrooms/mushroom_golden_5.png';

import liberty1 from './assets/shrooms/mushroom_liberty_1.png';
import liberty2 from './assets/shrooms/mushroom_liberty_2.png';
import liberty3 from './assets/shrooms/mushroom_liberty_3.png';
import liberty4 from './assets/shrooms/mushroom_liberty_4.png';
import liberty5 from './assets/shrooms/mushroom_liberty_5.png';

import wavy1 from './assets/shrooms/mushroom_wavy_1.png';
import wavy2 from './assets/shrooms/mushroom_wavy_2.png';
import wavy3 from './assets/shrooms/mushroom_wavy_3.png';
import wavy4 from './assets/shrooms/mushroom_wavy_4.png';
import wavy5 from './assets/shrooms/mushroom_wavy_5.png';

const App = () => {

  const [name, onChange] = useState(0);
  const mushrooms = [
    [0,
      1,
      "Flying Saucer"],
    [1,
      1.25,
      "Wavy Cap"],
    [2,
      1.5,
      "Golden Teacher"],
    [3,
      2,
      "Blue Meanies"],
    [4,
      3,
      "Liberty Cap"],
  ]

  let mushroomName = "";
  mushroomName = mushrooms[name][2];

  const [number, onChange2] = useState(1);
  let rarityImage = "";
  let spore = null;
  spore = number * mushrooms[name][1]
  let mushroomImages = flying1
  mushroomImages = [
    [
      mushrooms[0],
      flying1,
      flying2,
      flying3,
      flying4,
      flying5
    ],
    [
      mushrooms[1],
      wavy1,
      wavy2,
      wavy3,
      wavy4,
      wavy5
    ],
    [
      mushrooms[2],
      golden1,
      golden2,
      golden3,
      golden4,
      golden5
    ],
    [
      mushrooms[3],
      blue1,
      blue2,
      blue3,
      blue4,
      blue5
    ],
    [
      mushrooms[4],
      liberty1,
      liberty2,
      liberty3,
      liberty4,
      liberty5
    ]
  ];

  rarityImage = mushroomImages[name][number];

  return (
    <div className="App">
      <Navbar collapseOnSelect expand="xs" bg="transparent" variant="light">
        <Container fluid>
          <Row className="content-row social-bar">
            <Col>
              <a  target="_blank" href="https://discord.gg/B6BGHj8xsy">
                <Discord size={16} />
              </a>
              <a  target="_blank" href="https://twitter.com/CouncilOfElves">
                <Twitter size={16} />
              </a>
              <a  target="_blank" href="https://medium.com/@council.of.elves">
                <Medium size={16} />
              </a>
            </Col>
          </Row>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav.Item>
              <Nav.Link href="#introduction">Introduction</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#rarity">Rarity</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#mentoring">Mentoring</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#triptalks">Trip talks</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#forest">Forest</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#creators">Creators</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#faq">FAQ</Nav.Link>
            </Nav.Item>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid='true' className='hero'>
        <Parallax bgImage={layer7} strength={500} className="layer">
        </Parallax>
        <Parallax bgImage={layer8} strength={450} className="layer">
        </Parallax>
        <Parallax bgImage={layer6} strength={400} className="layer">
        </Parallax>
        <Parallax bgImage={layer5} strength={350} className="layer">
        </Parallax>
        <Parallax bgImage={layer4} strength={300} className="layer">
        </Parallax>
        <Parallax bgImage={layer3} strength={250} className="layer">
        </Parallax>
        <Parallax bgImage={layer2} strength={200} className="layer mushroom">
        </Parallax>
        <div className="top-layer layer fullscreen">
        </div>
      </Container>
      <Container fluid className='content no-margin no-padding'>
        <Container fluid className='block-holder block-mintbutton text-center'>
          <a  target="_blank" href="https://discord.gg/B6BGHj8xsy" className='btn btn-primary btn-mint'>Join Community</a>
        </Container>
        <Container fluid className='block-holder block-introduction' id="introduction">
          <Container className="content-block text-center">
            <Row className='block-title'>
              <Col>
                <h2>INTRODUCTION</h2>
                <h3>Advanced crypto users only</h3>
                <h4>Not your regular drop! Read carefully!</h4>
              </Col>
            </Row>
            <Row className="content-row">
              <Col className="text-holder text-right">
                <p>
                  The Council of Elves is a fully decentralised procedurally generated NFT series of 5000 NFTs, Mushrooms and Elves, where the creators yield control to a community, and allow members of the community to become sole stakeholders. The potential for growth is immense, and the direction is determined by the Elves.
                </p>
                <p>
                  Council of Elves was created as a germination space within the NFT scene. There are countless projects popping up every day, but they flame out as fast as they come. For a project to sustain itself, it needs a strong community, and ample room for that community to define itself.
                </p>
                <p>
                  Our aim was to gather people with similar experiences—NFT psychonauts and psychedelic explorers. As we were building the concept, we realised that we can only provide the seed of the project, its ultimate direction has to be decided by the community. We think that this is the key to Council of Elves: it is the space, it is the beautiful artwork, and it is the people within the circle.
                </p>
              </Col>
              <Col xs={{ order: 'first' }} md={{ order: 'last' }} className="image-holder">
                <Image src={introductionImage}></Image>
              </Col>
            </Row>
            <Row className='block-title'>
              <Col>
                <h4>This is the power we want to tap into. Come, join us on this trip.</h4>
              </Col>
            </Row>
            <Row fluid className="content-row">
              <Col>
                <Accordion flush>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Phase #1 - Germination</Accordion.Header>
                    <Accordion.Body>
                      <p className="text-left">The first phase is when the bulk of the project unfolds. 2,000 tokens are released into the world, the Council is established, and the keys to the kingdom are handed over.
                      </p >
                      <p className="text-left">
                        Detailed steps:
                      </p>
                      <ul className="text-left">
                        <li>Initial Mushroom (1,900 tokens) and Elf (100 tokens) distribution</li>
                        <li>Community Contract, NFT contract, ERC-20 contract, and the Burn contract are deployed</li>
                        <li>Secondary sale commission (5%) is configured, to be sent to the Community Contract</li>
                        <li>The Elves establish the Council</li>
                      </ul>
                      <p className="text-left">The creators burn their keys to the contracts, OpenSea and LooksRare collections will be ownerless, further modification will be impossible. Secondary sales revenue always ends up in the Community Contract.
                      </p>
                      <p className="text-left">This is when the newly established Council takes its first vote. Will they continue the sale or stop it? Initially, only 2000 tokens are released, but the Council has the option to mint a further 3000 Mushrooms. Are they selling the rest of the collection for ETH or for $spore? Check FAQ for more on this.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Phase #2 - Fruition</Accordion.Header>
                    <Accordion.Body>
                      <p className="text-left">A new chapter opens. From now on, the community and especially the Council have complete control. This is now your project. This is your chance to make your mark on the NFT scene.

                      </p>
                      <p className="text-left">After the initial sale, Elves nominate 7 Elves. Those Elves will guard the Keys of the community, get full access to all linked profiles, and protect all social account access that belongs to the project.

                      </p>
                      <p className="text-left">Developers revoke their access to all linked accounts.

                      </p>
                      <p className="text-left">The project is fully decentralised, and the Elves drive the DAO. They have control over the Community Wallet, their decisions and involvement will determine the future of the project. It is our hope that our core, dedicated and motivated community will exercise their power to the benefit of all holders.

                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Phase #3 - Commencement</Accordion.Header>
                    <Accordion.Body>
                      <p className="text-left">
                        Hope is all well and good, you may say, but how can holders make sure that their investment is safe, that their time and effort pays off, and the fruits of Council of Elves are salubrious and bountiful? This is how: the Burn Cycles begin.
                      </p>
                      <p className="text-left">
                        Burn cycles are the Community’s self-defence mechanism.
                      </p>
                      <p className="text-left">
                        Mushrooms may propose Dark Elves who are acting against the community. Mushroom token owners vote on Elves to be expelled, their tokens burnt. A maximum of 2 Elf tokens may be burnt in a burn cycle.
                      </p>
                      <p className="text-left">
                        Mushrooms may propose Toxic Mushrooms, to be stomped and burned in order to protect the community, to protect the health of the others. A maximum of 20 Mushrooms can be burnt in a burn cycle.
                      </p>
                      <p className="text-left">
                        A new burn cycle may be started 3 days after the previous one closed and executed.
                      </p>
                      <p className="text-left">
                        There will be a total of 10 cycles.
                      </p>
                      <p className="text-left">
                        Check FAQ for more on this.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Phase #4 - Propagation</Accordion.Header>
                    <Accordion.Body>
                      <p className="text-left">
                        After the burn cycles, the community is strong and healthy. Elves and Mushrooms work together, nourishing and nurturing each individual with experience and wisdom, working together to continue to strengthen the community. They help each other to grow according to their inner values.
                      </p>
                      <p className="text-left">
                        Keep in mind that from the moment the Council is established, it has power over the Community Wallet. Will they use those funds to back other NFT projects? Perhaps they want to support the cause of psychedelic research or help those who have strayed from the straight and narrow path. Maybe they’ll turn towards their sisters and brothers all over the world who need any help they can get. We cannot tell you, as only they can make those decisions.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
          </Container>
        </Container>
        <Container fluid className='block-holder block-ape no-padding no-margin'>
          <div className='ape-image no-margin no-padding'></div>
          <Container className='content-holder'>
            <Row className='content-row'>
              <Col>

              </Col>
              <Col className='text-left'>
                <h3>Our roots entwined</h3>
                <h4>A note about psychedelics</h4>
                <p>
                  Psychedelic medicine has been a huge influence on us personally, and we know we’re not alone with these experiences. Some have gone further, going as far as suggesting that magical fungi have been instrumental in the development of our species.
                </p>
                <p>
                  Psychedelic pioneer and ethnobotanist Terence McKenna and his brother, Dennis McKenna put forward the idea in 1992 and argued that the mushroom Psilocybe cubensis became part of early human ancestors' diet, which prompted development in the eyes and the brain, and helped early humans become better hunters.
                </p>
                <p>
                  We chose the Mushroom to gather around because it symbolises growth energy, and because we have learned and grown so much in our past experiences with funghi.
                </p>
                <p>
                Are we then the product of mushrooms as much as the more potent varieties are a product of many years of experimentation and improvement? Dig deeper, look within, stare into the abyss, and see what stares back at you.
                </p>
              </Col>
            </Row>
          </Container>
        </Container>
        <Container fluid className='block-holder block-rarity' id="rarity">
          <Container className="content-block text-center">
            <Row className='block-title'>
              <Col>
                <h2>RARITY</h2>
                <h3>Some Mushrooms are more potent than others</h3>
                <h4>$SPORE is coming from the Mushrooms</h4>
              </Col>
            </Row>
            <Row className="content-row">
              <Col className="text-holder text-right">
                <p>
                  All Mushroom tokens are generated using five distinct traits: the Eye, the ornamental Skull Frame, the Flora in the foreground, the Background, and most importantly, the Mushrooms. Not all tokens are created equal, and the traits work differently, too. The number and variety of mushrooms on your Mushroom token affects the amount of $spore it generates: more mushrooms means more $spore generated, and the five varieties produce different amounts, too.
                </p>
                <div className="slider-parent">
                  <div className='slider-text-holder'>
                    <div className="shroom-prop-name">
                      Shroom Type
                    </div>
                    <div className="shroom-name">
                      {mushroomName}
                    </div>
                  </div>
                  <input type="range" min="0" max="4" step="1" value={name}
                    onChange={({ target: { value: name } }) => {
                      onChange(name);
                    }}
                  />
                </div>
                <div className="slider-parent">
                  <div className='slider-text-holder'>
                    <div className="shroom-prop-name">
                      Shroom Number
                    </div>
                    <div className="shroom-name">
                      {number}
                    </div>
                  </div>
                  <input type="range" min="1" max="5" step="1" value={number}
                    onChange={({ target: { value: number } }) => {
                      onChange2(number);
                    }}
                  />
                </div>
                <div className='btn btn-light btn-spore'>
                  <div className='slider-text-holder'>
                    <div className="shroom-prop-name">
                      $SPORE / DAY
                    </div>
                    <div className="shroom-spore">
                      {spore}
                    </div>
                  </div>
                </div>

              </Col>
              <Col className="image-holder">
                <Image className='rarity-img' src={rarityImage}></Image>
              </Col>
            </Row>
          </Container>
        </Container>
        <Container fluid className='block-holder block-mentoring' id="mentoring">
          <Container className="content-block text-center">
            <Row className='block-title'>
              <Col>
                <Image src={mentoringEye}></Image>
                <h2>MENTORING</h2>
                <h3>Learn and help others</h3>
                <p>
                  We are convinced that many among us have managed to ground themselves and are ready to explore new perspectives together. These creative energies need space to flourish, like a river needs its banks to wash away mountains.
                </p>
              </Col>
            </Row>
            <Row fluid className="content-row">
              <Col>
                <Accordion flush>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>I AM NEW TO CRYPTO AND NFTS</Accordion.Header>
                    <Accordion.Body>
                      <p className="text-left">
                        Council of Elves is a remarkably open project. Every decision made is a result of collaboration, different voices reaching agreement. Because of this open exchange of ideas, newcomers to the NFT and crypto scenes can gain insight and discover perspectives that would otherwise have stayed hidden from them.

                      </p>
                      <p className="text-left">
                        If you want to dig deeper into the nourishing medium, you can join our AMA sessions, participate in Trip Talks when we talk about the latest in NFT (scroll down for more on this), or add your ideas to the decision-making process. The more you absorb, the more this world will open up to you. Join us on our trip down the rabbit hole.

                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>I AM ADVANCED IN CRYPTO AND NFTS</Accordion.Header>
                    <Accordion.Body>
                      <p className="text-left">
                        For advanced users, the project offers a unique chance to grow with minimal risk. Beyond great opportunities to meet like-minded users from a huge spectrum of experience levels, Council of Elves offers a chance to make your mark on the NFT scene—without having to build a project from scratch.

                      </p>
                      <p className="text-left">
                        For those willing to invest their time and join the Council, even more possibilities open up, both inside and outside the NFT scene. You can raise up other projects, create value, build a team, reach new audiences—possibilities come prepackaged. What will you do when you’re handed the Keys to the Kingdom?

                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
          </Container>
        </Container>
        <Container fluid className='block-holder block-triptalks' id="triptalks">
          <Container className="content-block text-center">
            <Row className='block-title'>
              <Col>
                <h2>TRIP TALKS</h2>
                <h3>Down the crypto and NFT rabbit hole</h3>
              </Col>
            </Row>
            <Row className="content-row">
              <Col className="image-holder">
                <Image src={triptalksImage}></Image>
              </Col>
              <Col className="text-holder text-left">
                <p>
                  We all come into this world innocent and blind—we grow strong and wise with the help of others and through our experiences. The same is true for the NFT scene; in this new and flourishing world we are surrounded by inventors, pioneers, and investors, whose insight can mean a world of difference for those who came later to the party. What’s more, experienced users who are familiar with one area may find the perspective to those coming to the scene from another direction enlightening.                </p>
                <p>
                  Sharing is caring, sharing is growing together. Together and strong.
                </p>
                <p>
                  Every week, the Council of Elves Trip Talks bring these unique perspectives to our community. Our guests will talk, among other topics, about investment, production, world building, community building, latest developments, and the future of NFTs.
                </p>
                <p>
                  Check FAQ for more on this.
                </p>
              </Col>
              <Row>
                <Col>
                  <a  target="_blank" href="https://discord.gg/B6BGHj8xsy" className='btn btn-light'>Join trip talks</a>
                </Col>
              </Row>
            </Row>
          </Container>
        </Container>
        <Container fluid className='block-holder block-forest' id="forest">
          <Container className="content-block text-center">
            <Row className='block-title'>
              <Col>
                <h2>MUSHROOMS FOR THE FOREST</h2>
                <h3>We plant a tree after every NFT minted</h3>
              </Col>
            </Row>
            <Row className="content-row">
              <Col>
                <p>
                  We are people of the Forest, people of the Trees, and this is an issue close to our hearts. The Council will have the funds to support whatever cause they choose, but we want to do something as well.
                </p>
                <p>
                  The power of trees in enhancing biodiversity and facilitating carbon sequestration cannot be overstated. We want to do our part in the global reforestation effort, so every NFT sold directly leads to a tree planted. Although the carbon absorption capacity can vary, it is generally considered that a tree can store about 167 kg of CO2 per year, so the more trees we plant, the more we reduce our carbon footprint.
                </p>
                <a href="https://discord.gg/B6BGHj8xsy" className='btn btn-light'>Join community</a>
              </Col>
            </Row>
          </Container>
        </Container>
        <Container fluid className='block-holder block-creators' id="creators">
          <Container className="content-block text-center">
            <Row className='block-title'>
              <Col>
                <h2>CREATORS</h2>
                <h3>The minds behind the project</h3>
              </Col>
            </Row>
            <Row className="content-row">
              <Col className="image-holder" md="2">
                <Image className="creator-img" src={creator1Image}></Image>
              </Col>
              <Col md="10" className="creator-text text-left">
                <h5>
                  Phaedrus - lead psychonaut
                </h5>
                <p>
                  The deep does not scare, nor vast vistas shock this chap. He’s bringing the vision, the concept, the writing, and community building to the project.
                </p>
                <Row className="content-row social-bar">
                  <Col>
                    <a  target="_blank" href="https://twitter.com/Phaedrus314">
                      <Twitter size={16} />
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="content-row">

              <Col md="10" className="creator-text text-right">
                <h5>
                  PsySpiral - creative
                </h5>
                <p>
                  All the look and feel is his domain. With many projects, crypto, and NFT creations behind his back, this project is his masterpiece.
                </p>
                <Row className="content-row social-bar">
                  <Col>
                    <a href="https://twitter.com/psychospiral1?s=21">
                      <Twitter size={16} />
                    </a>
                  </Col>
                </Row>              </Col>
              <Col className="image-holder" md="2" xs={{ order: 'first' }} md={{ order: 'last' }}>
                <Image className="creator-img" src={creator2Image}></Image>
              </Col>
            </Row>
            <Row className="content-row">
              <Col className="image-holder" md="2">
                <Image className="creator-img" src={creator3Image}></Image>
              </Col>
              <Col md="10" className="creator-text text-left">
                <h5>
                  InsaneHits - dev
                </h5>
                <p>
                  His forays into the world of crypto started when the scene was still in its infancy, and we all learned from him. All that goes on behind scenes, the programming, the nitty-gritty is his brainchild.
                </p>
                <Row className="content-row social-bar">
                  <Col>
                    <a  target="_blank" href="https://twitter.com/insanehitz?s=11">
                      <Twitter size={16} />
                    </a>
                  </Col>
                </Row>              </Col>
            </Row>
            <Row className="content-row">
              <Col md="10" className="creator-text text-right">
                <h5>
                  JosefK - art
                </h5>
                <p>
                  Some people get all the talent, and our amazing artist is one of those people. His work is front and centre to the project, he created the Mushrooms and Elves.
                </p>
                {/* <Row className="content-row social-bar">
                  <Col>
                    <a href="https://twitter.com/gaborzubor">
                      <Twitter size={16} />
                    </a>
                  </Col>
                </Row>               */}
                </Col>
              <Col className="image-holder" md="2" xs={{ order: 'first' }} md={{ order: 'last' }}>
                <Image className="creator-img" src={creator4Image}></Image>
              </Col>
            </Row>
            <Row className="content-row">
              <Col className="image-holder" md="2">
                <Image className="creator-img" src={creator5Image}></Image>
              </Col>
              <Col md="10" className="creator-text text-left">
                <h5>
                  sunrize
                </h5>
                <p>
                  UX and UI specialist and inventor of magical worlds you can access through a click and a screen. This beautiful website is his achievement.
                </p>
                <Row className="content-row social-bar">
                  <Col>
                    <a  target="_blank" href="https://twitter.com/GaborZubor/">
                      <Twitter size={16} />
                    </a>
                  </Col>
                </Row>              </Col>
            </Row>
          </Container>
        </Container>
        <Container fluid className='block-holder block-faq' id="faq">
          <Container className="content-block text-center">
            <Row className='block-title'>
              <Col>
                <h2>FAQ</h2>
                <h3>Questions & Answers</h3>
              </Col>
            </Row>
            <Row fluid className="content-row">
              <Col>
                <Accordion flush>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>What is the Council of Elves?</Accordion.Header>
                    <Accordion.Body>
                      <p className="text-left">Council of Elves is the collective name for all the Elf and Mushroom token holders.
                        Together, they drive the DAO and care for our community.

                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>What’s the total supply?</Accordion.Header>
                    <Accordion.Body>
                      <p className="text-left">The initial distribution consists of 2,000 tokens, of which 1,900 are Mushrooms and 100 are Elves. The distribution of Elves v Mushrooms is random.
                        The entire collection is 5,000 NFTs, but not all will be minted. Once initial distribution is complete, the Elves will take over the project, and they will decide whether to continue minting. There are no more Elf tokens after initial distribution.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>What can the Elves vote on during the sale?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="text-left">The Elves can vote on a number of things as part of the NFT contract:
                      </p>
                      <ul>
                        <li>
                          stopping the sale
                        </li>
                        <li>
                          stopping $spore token emission
                        </li>
                        <li>
                          if the sale continues, whether new NFT are minted for $spore or for ETH
                        </li>
                        <li>
                          if the sale continues, whether to double the $spore price per NFT
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>How will the community contract be funded?</Accordion.Header>
                    <Accordion.Body>
                      <p className="text-left">
                        After initial distribution, the Elves have a decision to make: continue with the mint or stop.

                      </p>
                      <p className="text-left">
                        If they decide to stop, 10% of the sale will go directly to the community contract. There is also 5% commission on secondary sales, which also goes directly to the community contract.

                      </p>
                      <p className="text-left">
                        Same thing happens if the Elves decide to stop ETH sales and continue minting for $spores.

                      </p>
                      <p className="text-left">
                        However, if the Elves decide to continue minting for ETH after the initial distribution, 100% of the sales from NFT #2001 onwards will go to the community contract, as well as the 5% commission from secondary sales. The 10% of the initial distribution will go to the creators.

                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>How will the funds of the community contract be allocated?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="text-left">That is up to the Elves. Each Elf token holder can withdraw their share of ETH from the community contract to their personal wallet at any time. As for the rest, we hope that the Elves will apply their infinite wisdom and spend those funds in a way that benefits the whole community.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>Will there be a DAPP?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="text-left">No, all contract interactions are on Etherscan.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>What’s the date for the drop?</Accordion.Header>
                    <Accordion.Body>
                      <p className="text-left">TBA on our Discord server.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="7">
                    <Accordion.Header>How many can I mint?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="text-left">The contract will allow 5 tokens per transaction.

                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="8">
                    <Accordion.Header>What is the set mint price?</Accordion.Header>
                    <Accordion.Body>
                      <p className="text-left">Whitelist price will be 0.05 ETH + gas.
                        Public mint starts at 0.07 ETH + gas, drops to 0.06 ETH on day two, 0.05 ETH on day three, and so on each day until it’s free. This way we make sure the initial distribution is sold out.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="9">
                    <Accordion.Header>Is there a whitelist?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="text-left">A few chosen communities will be allowed to mint first. The names of those communities will be disclosed before the drop. If there are tokens left, the public sale begins.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="10">
                    <Accordion.Header>What’s the royalty rate?</Accordion.Header>
                    <Accordion.Body>
                      <p className="text-left">The rate on OpenSea is 5%, which goes to the Community Contract, and the Elves get to decide what to do with it.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
          </Container>
        </Container>
        <Container fluid className='block-holder block-footer'>
          <Container className="content-block text-center">
            <Row className='block-title'>
              <Col>
                <Image src={logo}></Image>
              </Col>
            </Row>
            <Row className="content-row">
              <Col>
                <Nav className="justify-content-center" activeKey="/home">
                  <Nav.Item>
                    <Nav.Link href="#introduction">Introduction</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="#rarity">Rarity</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="#mentoring">Mentoring</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="#triptalks">Trip talks</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="#forest">Forest</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="#creators">Creators</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="#faq">FAQ</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
            </Row>
            <Row className="content-row social-bar">
              <Col>
                <a  target="_blank" href="https://discord.gg/B6BGHj8xsy">
                  <Discord size={16} />
                </a>
                <a  target="_blank" href="https://twitter.com/CouncilOfElves">
                  <Twitter size={16} />
                </a>
                <a  target="_blank" href="https://medium.com/@council.of.elves">
                  <Medium size={16} />
                </a>
              </Col>
            </Row>
          </Container>
        </Container>
        <Container fluid className='block-holder block-footer-bottom'>
          <Container className="content-block text-center">
            <Row className='block-title'>
              <Col>
                <p className='text-italic'>All Rights Reserved 2022</p>
              </Col>
            </Row>
          </Container>
        </Container>
      </Container>
    </div >
  );
}

export default App;
